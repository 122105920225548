<template>
  <div id="CoursesRegister">
    <button
      @click="add()"
      style="background-color: #1d425e; color: white"
      class="btn mb-3"
    >
      Añadir Curso
    </button>
    <div>
      <b-modal scrollable centered v-model="show" title="Añadir curso">
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="error" />
            <div class="form-group">
              <label for="validationServer01">Nombre</label>
              <b-form-input
                type="text"
                class="form-control"
                placeholder="Ingrese nombre"
                v-model="data.name"
              />
            </div>
            <div class="form-group">
              <label for="validationServer01">Acrónimo</label>
              <b-form-input
                type="text"
                class="form-control"
                placeholder="Ingrese acrónimo"
                v-model="data.acronym"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Descripción</label>
              <b-form-textarea
                placeholder="Ingrese descripción"
                v-model="data.description"
              />
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem(data)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AlertError from "../../../components/AlertError.vue";
export default {
  name: "CoursesRegister",
  components: { AlertError },
  props: {
    idIdentifier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      data: {},
    };
  },
  methods: {
    exit() {
      this.show = false;
    },
    add() {
      (this.data = {
        name: "",
        acronym: "",
        description: "",
      }),
        (this.show = true);
    },
    async saveAddItem() {
      await this.$axios
        .post(
          "/configuration/modulus/" + this.idIdentifier + "/course-register",
          this.data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.$refs.error.showAlert(e.response.data.content);
        });
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>